import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-main" }

import MainInformation from "@/components/main/MainInformation.vue";
import MainActivities from "@/components/main/MainActivities.vue";
import MainEducations from "@/components/main/MainEducations.vue";
import MainProjects from "@/components/main/MainProjects.vue";
import MainEmployments from "@/components/main/MainEmployments.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'MainView',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[0] || (_cache[0] = _createElementVNode("section", { class: "content-hr bottom" }, null, -1)),
    _createElementVNode("section", _hoisted_1, [
      _createVNode(MainInformation),
      _createVNode(MainEmployments),
      _createVNode(MainActivities),
      _createVNode(MainEducations),
      _createVNode(MainProjects)
    ])
  ], 64))
}
}

})