export default {
  _number_format: {
    st: "",
    th: "",
    nd: "",
  },
  loading: "페이지 로딩하는 중...",
  nav: {
    cv: "이력",
    activity: "활동",
    project: "프로젝트",
  },
  intro: {
    name: "박찬솔",
    description: "소프트웨어 엔지니어",
    word: ["더 나은 세상을 만드는", "소프트웨어를 개발합니다"],
  },
  notfound: {
    message: "찾으시는 페이지가 없습니다{back}",
    back: "돌아가기",
  },
  main: {
    employments: {
      header: "경력 사항",
      moloco: {
        header: "몰로코, 소프트웨어 엔지니어 인턴{duration}",
        duration: "(2024.11 - 2025.02)",
        details: [
          "머신러닝 데이터 셋 파이프라인 구축과 광고 타깃팅 조건 추가를 위한 First-party 데이터 처리 등 파트너 회사들을 위한 서비스 개발",
        ],
      },
      karrot: {
        header: "당근, 소프트웨어 엔지니어 인턴 (백엔드){duration}",
        duration: "(2024.08 - 2024.11)",
        details: [
          "여러 서비스로부터 생성되는 데이터를 다른 팀으로 제공하는 컨텐츠 큐레이터 서비스 개발",
          "Kafka로부터 새롭게 들어오는 데이터 또는 gRPC로부터 누락된 데이터를 관계형 데이터베이스에 저장하고, 이를 기반하여 동작하는 캐시 레이어 개발. 이 최적화를 통해 분당 60-70회의 gRPC 호출이 분당 1회 미만으로 감소",
          "개발 단계에서 잠재적인 오류를 확인하고 안정성을 개선할 수 있는 통합 테스트 추가",
        ],
      },
    },
    educations: {
      header: "학력",
      bachelor: {
        header: "숭실대학교 컴퓨터학부{duration}",
        duration: "(2022.03 - 2025.02)",
        grade: "평점 4.29 / 4.50{rank}",
        rank: "(졸업 석차 2/77)",
        scholarship: "이공계 국가 우수 장학생{duration}",
        scholarship_duration: "(2024.11)",
        undergraduate: "숭실대학교 데이터베이스 연구실 학부연구생{duration}",
        undergraduate_duration: "(2022.03 - 2025.02)",
        sccc: {
          header: "컴퓨터학부 문제해결 소모임 SCCC 회장 및 임원",
          study: "알고리즘, 기초 C언어, 논리회로(디지털 공학) 스터디 운영",
          scon: "2023 숭실대학교 프로그래밍 대회 총괄{duration}",
          scon_duration: "(2023.04)",
          homepage: "SCCC 홈페이지",
        },
        completed_subject: "대학에서 이수한 주요 과목 목록",
      },
    },
    activities: {
      header: "활동",
      algorithm: {
        header: "알고리즘 대회 참가{duration}",
        duration: "(2019 - )",
        icpc: {
          icpcwf: "제47회 ICPC World Finals, Honorable{duration}",
          icpcwf_duration: "(2024.04, Luxor, Egypt)",
          icpc2022:
            "2022 ICPC Asia Seoul Regional, 은상 (5등, 과학기술정보통신부 장관상){duration}",
          icpc2022_rank: "",
          icpc2022_duration: "(2022.11)",
        },
        koi_ta: {
          header: "한국정보올림피아드 위원회 코치{duration}",
          duration: "(2023.08 - )",
          koi2024first:
            "한국정보올림피아드 2024년 1차 대회 프로그래밍 코디네이터{duration}",
          koi2024first_duration: "(2024.05)",
          ioi_camp: "국제정보올림피아드 교육생(계절학교) 코치{duration}",
          ioi_camp_duration: "(2023.08 - 2024.08)",
        },
        koi_award: {
          first_general:
            "제38회 한국정보올림피아드 1차 대회 일반고 부문 은상{duration}",
          first_duration: "(2021.05)",
        },
        view_all: "알고리즘 관련 모든 활동 보기",
      },
      software: {
        header: "소프트웨어 활동{duration}",
        duration: "(2020 - )",
        soma: {
          header: "SW마에스트로 13기{duration}",
          duration: "(2022.04 - 2022.11)",
          project: "코드맵, 알고리즘 대회 준비를 위한 플랫폼 - {detail}",
          project_detail: "프로젝트 상세 보기",
        },
      },
    },
    information: {
      header: "정보",
      name: {
        key: "이름",
        value: "박찬솔",
      },
      alt_name: {
        key: "대체 이름",
        value: "솔린(Solrin)",
      },
      email: {
        key: "이메일",
        value: "sorisem4106{'@'}naver.com",
      },
      place: {
        key: "위치",
        value: "대한민국 서울",
      },
      nation: {
        key: "국적",
        value: "대한민국",
      },
      external: "외부 링크",
      github: {
        key: "Github",
        value: "nnnlog",
      },
      linkedin: {
        key: "LinkedIn",
        value: "열기",
      },
      icpc: {
        key: "ICPCID",
        value: "열기",
      },
    },
    projects: {
      header: "프로젝트",
      text: "{key}: Javascript/Typescript, Flutter, C++",
      key: "주로 사용하는 언어 및 프레임워크",
      link: "모든 프로젝트 보기",
    },
  },
  activities: {
    index: {
      header: "활동",
      algorithm: {
        header: "알고리즘 분야",
        desc1: "대회 참가",
        desc2: "대회 운영",
        desc3: "알고리즘 강의 강사",
        view: "알고리즘 관련 모든 활동 보기",
      },
      ssu: {
        header: "숭실대학교",
        desc1: {
          header: "이수한 주요 과목",
          desc1: "리눅스시스템프로그래밍, 운영체제",
          desc2: "네트워크프로그래밍, 컴퓨터네트워크",
          desc3: "컴퓨터비전",
        },
        desc2: "컴퓨터학부 문제해결 소모임 SCCC 회장 및 임원",
        view: "이수한 주요 교과목의 자세한 내용 보기",
      },
    },
    algorithm: {
      header: "알고리즘 관련 활동",
      participate_contest: {
        header: "대회 참가",
        icpc: {
          header: "ICPC",
          description: "(국제 대학생 프로그래밍 경시대회)",
          icpc2024: "2024 ICPC Asia Seoul Regional Honorable mention{duration}",
          icpc2024_duration: "(2024.11)",
          icpcwf: "제47회 ICPC World Finals Honorable mention{duration}",
          icpcwf_duration: "(2024.04, 룩소르, 이집트)",
          icpc2023: "2023 ICPC Asia Seoul Regional Honorable mention{duration}",
          icpc2023_duration: "(2023.11)",
          icpc2022:
            "2022 ICPC Asia Seoul Regional 은상 (5등, 과학기술정보통신부 장관상){duration}",
          icpc2022_rank: "",
          icpc2022_duration: "(2022.11)",
          icpcid: "ICPCID",
        },
        koi: {
          header: "KOI",
          description: "(한국정보올림피아드)",
          koi2: "제38회 한국정보올림피아드 2차 대회 고등부 장려상{duration}",
          koi2_duration: "(2021.07)",
          koi1_general:
            "제38회 한국정보올림피아드 1차 대회 일반고부문 은상{duration}",
          koi1_duration: "(2021.05)",
        },
        contest_univ: {
          header: "대학교 재학 중 참가한 대회",
          ucpc: {
            header: "UCPC",
            description: "(전국 대학생 프로그래밍 대회 동아리 연합)",
            ucpc2023: "UCPC 2023 본선 35등{duration}",
            ucpc2023_duration: "(2023.07)",
            ucpc2022: "UCPC 2022 본선 21등{duration}",
            ucpc2022_duration: "(2022.07)",
            icn2023: "인천대학교 코드 페스티벌 2023 장려상{duration}",
            icn2023_duration: "(2023.12)",
          },
        },
        contest_high: {
          header: "고등학교 재학 중 참가한 대회",
          kookmin: {
            header: "국민대학교 알고리즘 대회",
            kmu2021: "제6회 국민대학교 알고리즘 대회 동상{duration}",
            kmu2021_duration: "(2021.08)",
            kmu2020: "제5회 국민대학교 알고리즘 대회 장려상{duration}",
            kmu2020_duration: "(2020.08)",
          },
          busan: {
            header: "부산코딩경진대회",
            busan2021: "2021 부산코딩경진대회 대상{duration}",
            busan2021_duration: "(2021.08, 부산)",
            busan2020: "2020 부산코딩경진대회 대상{duration}",
            busan2020_duration: "(2020.09, 부산)",
            busan2019: "2019 부산코딩경진대회 동상{duration}",
            busan2019_duration: "(2019.11, 부산)",
          },
        },
      },
      operating: {
        header: "운영한 대회",
        koi: {
          header: "KOI{description}",
          description: "(한국정보올림피아드)",
          koi2024_1:
            "한국정보올림피아드 2024년 1차 대회 프로그래밍 코디네이터{duration}",
          koi2024_1_duration: "(2024.05)",
        },
        scon: {
          header: "SCON{detail}",
          description: "(숭실대학교 프로그래밍 대회)",
          scon2024: "2024 SCON 문제 출제{duration}",
          scon2024_duration: "(2024.05)",
          scon2023: "2023 SCON 총괄 및 문제 출제{duration}",
          scon2023_duration: "(2023.05)",
        },
      },
      lecture: {
        header: "알고리즘 강의 강사",
        koi: {
          header: "한국정보올림피아드 위원회{description}",
          description: "",
          ioi_camp: "국제정보올림피아드 교육생(계절학교) 코치{duration}",
          ioi_camp_duration: "(2023.08 - 2024.08, 대한민국)",
        },
        sinchon: {
          header: "ICPC Sinchon",
          summer2024: "2024 ICPC Sinchon 여름 캠프 초급 강사{duration}",
          summer2024_duration: "(2024.07 - 2024.08)",
          winter2024: "2024 ICPC Sinchon 겨울 캠프 초급 강사{duration}",
          winter2024_duration: "(2024.01 - 2024.02)",
          summer2023: "2023 ICPC Sinchon 여름 캠프 초급 강사{duration}",
          summer2023_duration: "(2023.07 - 2023.08)",
          material: "강의 자료",
        },
        sccc: {
          header: "SCCC{description}",
          description: "(숭실대학교 컴퓨터학부 문제해결 소모임)",
          study2024: "기초 C언어 스터디 강사{duration}",
          study2024_duration: "(2024.03 - 2024.05)",
          study2023: "그룹 스터디 강사(알고리즘/C언어){duration}",
          study2023_duration: "(2023.03 - 2023.05)",
        },
      },
    },
    univ: {
      header: "숭실대학교 주요 이수 과목",
      pl: {
        header: "프로그래밍언어",
        duration: "(2024-1학기)",
        github: "Github",
        hw: "EBNF에 기반하여 Recursive-Descent 구조를 사용한 간단한 파서 개발",
      },
      network: {
        header: "네트워크프로그래밍",
        duration: "(2024-1학기)",
        github: "Github",
        outline:
          "교과목 개요: TCP/UDP 소켓 통신, 메세지 큐, 동시성 프로그래밍, HTTP(s)",
        grade: "성적: A+",
        hw1: "TCP 위에서 동작하며, 한 서버가 한번에 여러 개의 클라이언트를 처리할 수 있는 간단한 게임 서버 개발",
        hw2: "Flask, SQLite를 사용한 게임 개발",
      },
      cv: {
        header: "컴퓨터비전",
        duration: "(2023-2학기)",
        github: "Github",
        outline:
          "교과목 개요: Canny 에지 검출기, 특징점 검출(SIFT)와 같은 고전적인 컴퓨터비전 알고리즘 학습",
        grade: "성적: A+",
        hw1: "Canny, k-NN 등을 사용하여 주어진 이미지 내에서 체커보드를 찾는 알고리즘 개발",
        hw2: "YOLO, SIFT 등을 사용하여 주어진 이미지 내에서 엠파이어 스테이트 빌딩을 찾는 프로그램 작성",
      },
      os: {
        header: "운영체제",
        duration: "(2023-2학기)",
        github: "Github",
        outline:
          "교과목 개요: 메모리/디스크 관리, 스케쥴러, xv6 함수 추가 및 수정, ext2 손 추적",
        grade: "성적: A+",
        hw: "xv6에 커스텀 스케쥴러 및 시스템 콜 추가, xv6 파일 구조 수정 (3중 간접 주소, 최대 1GB 파일 지원)",
      },
      lsp: {
        header: "리눅스시스템프로그래밍",
        duration: "(2023-1학기)",
        github: "Github",
        outline:
          "교과목 개요: C 프로그래밍, 리눅스 시스템 콜, 동시성 프로그래밍 (thread/mutex) 등",
        grade: "성적: A+ (1등)",
        hw: "디렉터리 모니터링 프로그램, 파일 백업/복원 프로그램, 프로그래밍 채점 프로그램 개발",
      },
    },
  },
  project: {
    index: {
      header: "프로젝트",
      ssurade: {
        header: "SSUrade",
        duration: "(2022.06 - )",
        description: "성적, 출결 등 학사 정보를 조회하는 애플리케이션",
        tech: "사용 기술: Flutter, Javascript",
        scale: "개인 프로젝트",
        playstore: "Play Store",
        playstore_installation_count: "Play Store 설치 수 : 1100회",
        view: "자세히 보기",
        github: "Github",
      },
      ssullabus: {
        header: "SSUllabus",
        duration: "(2024.02 - )",
        description:
          "여러 필터를 사용해 숭실대학교 개설 과목을 조회할 수 있는 웹 페이지",
        tech: "사용 기술: node.js(hono.js, playwright), solid.js, GraphQL, SQLite",
        scale: "개인 프로젝트",
        web: "Web Page",
        github: "Github",
      },
      codemap: {
        header: "Codemap",
        duration: "(2022.06 - 2022.12)",
        description:
          "(SW마에스트로 팀 프로젝트) 알고리즘 대회를 준비하는 웹 플랫폼",
        tech: "사용 기술: Vue.js, JWT, Github CI/CD",
        scale: "팀 프로젝트 (역할: 프론트엔드)",
        github: "Github (프론트엔드)",
      },
    },
    ssurade: {
      header: "SSUrade",
      duration: "(2022.06 - )",
      description: "성적, 출결 등 학사 정보를 조회하는 애플리케이션",
      tech: "사용 기술: Flutter, Javascript",
      scale: "개인 프로젝트",
      playstore: "Play Store",
      playstore_installation_count: "Play Store 설치 수 : 1100회",
      github: "Github",
    },
  },
};
